import { WSLoader } from "@wingspanhq/fe-component-library";
import React from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { useBankingAccount } from "../../../../query/bookkeeping/queries";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { PayoutMethodType } from "../../../../shared/types/payments";
import { RouteComponentProps } from "../../types";
import { Form } from "./Form";
import { useMutationSubmit } from "./useMutationSubmit";

export const RouteType: React.FC<RouteComponentProps> = ({
  onBack,
  onNext,
  params
}) => {
  const userId = useUserId();
  const featureFlagsQuery = useFeatureFlags();
  const payoutSettingsQuery = usePayoutSettings(userId);
  const [submit, submitMeta] = useMutationSubmit({ onSuccess: onNext });
  const queryBankingAccount = useBankingAccount();

  if (queryBankingAccount.isLoading) {
    return <WSLoader.Spinner />;
  }

  return (
    <LayoutPaymentsSetup onBack={onBack}>
      <WSQueries queries={{ featureFlagsQuery, payoutSettingsQuery }}>
        {({
          featureFlagsQuery: { data: featureFlags },
          payoutSettingsQuery: { data: payoutSettings }
        }) => {
          const defaultPayoutMethods: PayoutMethodType[] =
            featureFlags.giftCards
              ? [
                  PayoutMethodType.Instant,
                  PayoutMethodType.Wallet,
                  PayoutMethodType.ACH,
                  PayoutMethodType.GiftCard
                ]
              : [
                  PayoutMethodType.Instant,
                  PayoutMethodType.Wallet,
                  PayoutMethodType.ACH
                ];

          return (
            <Form
              payoutSettings={payoutSettings}
              internalAccount={queryBankingAccount.data}
              payoutMethods={
                params?.availablePayoutMethods || defaultPayoutMethods
              }
              onSubmit={(selectedPayoutMethod, paymentCardId) => {
                submit({ selectedPayoutMethod, paymentCardId });
              }}
              error={submitMeta.error}
              isLoading={submitMeta.isLoading}
            />
          );
        }}
      </WSQueries>
    </LayoutPaymentsSetup>
  );
};
