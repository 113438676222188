import { IS_LOCAL_ENV } from "../shared/constants/environment";

export const EMBEDDED_SOURCE = "ws-embedded";

export const sendMessageToParent = (event: string, data?: any) => {
  data = data || {};
  const message = JSON.stringify({
    source: EMBEDDED_SOURCE,
    payload: {
      event,
      data
    }
  });

  if (IS_LOCAL_ENV) {
    window.postMessage(
      message,
      "*" // we should pull this off the domain token
    );
  } else {
    window.parent.postMessage(
      message,
      "*" // we should pull this off the domain token
    );
  }
};

if (IS_LOCAL_ENV) {
  window.addEventListener("message", function (message) {
    if (message.origin === "http://localhost:3000") {
      try {
        const data = JSON.parse(message.data);
        if (data.source === "ws-embedded") {
          console.log("ws-embedded-message", data);
          return;
        }
      } catch {}
    }
  });
}

// example message handler
//
// window.addEventListener("message", function(message) {
//   if (
//     message.origin === "https://onboarder.wingspan.app" ||
//     message.origin === "https://staging-onboarder.wingspan.app"
//   ) {
//     try {
//       const data = JSON.parse(message.data);
//       if (data.source === "ws-embedded") {
//         console.log(data);
//         return;
//       }
//     } catch {}
//   }
// });
