import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import {
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import React, { useState } from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { useWSMutation } from "../../../../query/helpers";
import { useUserId } from "../../../../query/hooks/helpers";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { QUERY_PAYOUT_SETTINGS } from "../../../../query/payments/keys";
import { usePayoutSettings } from "../../../../query/payments/queries";
import { paymentsService } from "../../../../services/payments";
import { BankCard } from "../../../../shared/components/BankCard";
import { FormInputOnboardingOption } from "../../../../shared/components/FormInputOnboardingOption";
import { LayoutPaymentsSetup } from "../../../../shared/components/LayoutPaymentsSetup";
import { usePaymentCardForm } from "../../../../shared/hooks/usePaymentCardForm";
import { WSServiceError } from "../../../../utils/serviceHelper";
import { RouteComponentProps } from "../../types";

export const RouteInstant: React.FC<RouteComponentProps> = ({
  onBack,
  onNext
}) => {
  const userId = useUserId();
  const queryPayoutSettings = usePayoutSettings(userId);

  const [debitCardType, setDebitCardType] = useState<"existing" | "new">(
    "existing"
  );

  const [submit, submitMeta] = useWSMutation<
    any,
    WSServiceError,
    { paymentCardId?: string }
  >(
    async ({ paymentCardId }) => {
      if (debitCardType === "existing") {
        const debitCardDestination =
          queryPayoutSettings.data?.payoutDestinations.find(
            d => d.destinationType === PayoutDestinationType.Card
          );

        if (debitCardDestination) {
          return await paymentsService.payoutSettings.update(userId, {
            payoutPreferences: PayoutPreferences.Instant,
            instant: {
              destinationId: debitCardDestination?.destinationId,
              destinationType: PayoutDestinationType.Card
            }
          });
        }
      } else if (debitCardType === "new" && paymentCardId) {
        return await paymentsService.payoutSettings.update(userId, {
          payoutPreferences: PayoutPreferences.Instant,
          instant: {
            destinationId: paymentCardId,
            destinationType: PayoutDestinationType.Card
          }
        });
      }
      return { debitCardType };
    },
    {
      dependencies: [QUERY_PAYOUT_SETTINGS],
      onSuccess: onNext
    }
  );

  const queryFeatureFlags = useFeatureFlags();
  const paymentCardForm = usePaymentCardForm();

  return (
    <LayoutPaymentsSetup title="Instant payouts to debit card" onBack={onBack}>
      <WSQueries queries={{ queryPayoutSettings }}>
        {({ queryPayoutSettings: { data: payoutSettings } }) => {
          const debitCardDestination = payoutSettings.payoutDestinations.find(
            d => d.destinationType === PayoutDestinationType.Card
          );

          return (
            <>
              <FormInputOnboardingOption
                mb="M"
                name="debitCardType"
                value="existing"
                checked={debitCardType === "existing"}
                onChange={setDebitCardType}
              >
                {debitCardDestination?.destinationId && (
                  <BankCard
                    type="debit"
                    cardId={debitCardDestination?.destinationId}
                  />
                )}
              </FormInputOnboardingOption>

              <FormInputOnboardingOption
                mb="M"
                name="debitCardType"
                value="new"
                checked={debitCardType === "new"}
                onChange={setDebitCardType}
                title="Add new debit card"
              >
                <WSText>Manually enter your routing and account numbers</WSText>
              </FormInputOnboardingOption>

              <WSButton
                mb="XL"
                name="continue"
                onClick={() => {
                  if (
                    queryFeatureFlags.data?.paymentCardAsPayoutMethod &&
                    debitCardType === "new"
                  ) {
                    paymentCardForm.open({
                      onSuccess: paymentCard => {
                        submit({ paymentCardId: paymentCard.paymentCardId });
                      }
                    });
                  } else {
                    submit();
                  }
                }}
                loading={submitMeta.isLoading}
                fullWidth
              >
                Continue
              </WSButton>
            </>
          );
        }}
      </WSQueries>
    </LayoutPaymentsSetup>
  );
};
